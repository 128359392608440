import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from 'react-bootstrap/NavLink';
import Container from 'react-bootstrap/Container';
import { NavbarBrand } from 'react-bootstrap';

export const BingoNav = ({ }) => {
  return (
    // <Router>
      <Navbar bg="light" expand="lg">
        <Container fluid={true}>
          <Link className="navbar-brand" to="/">Sosshial B4 Bingo</Link>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-item nav-link" to="/">Home</Link>
              <Link className="nav-item nav-link" to="/rules">Rules</Link>
              <Link className="nav-item nav-link" to="/patterns">Patterns</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    // </Router>

  );
};
