import React, { Component } from 'react';

class SpotifySearchArtistItem extends Component {
    // same as getInitialState() in reactjs
    constructor(props) {
        super(props);
    }

    render() {
        // return the following code
        return (
            <>
                <h1>Artists matching {this.props.searchTerm}</h1>
                <div className="col-4">
                    <h3>{this.props.name}</h3>
                </div>
                <div className="col-2">
                    <img src={this.props.image} width="250" height="250"></img>
                </div>
            </>
        );
    };
}
export default SpotifySearchArtistItem;