import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { BingoNav } from './components/BingoNav/BingoNav';
import BingoCard4 from './components/BingoCard4/BingoCard4';
import SpotifySearchResultList from './components/SpotifySearchResultList/SpotifySearchResultList';
//import SpotifySearchInput from './components/SpotifySearchInput/SpotifySearchInput';

import './App.css';
const dh = require('./DataHelper.js');

function App() {
  const [patternList, setPatternList] = useState([]);
  const [patternCache, setPatternCache] = useState(undefined);
  const [patternCacheExpired, setPatternCacheExpired] = useState(true);
  const mockDataHelper = new dh.MockDataHelper();

  useEffect(() => {
    //getPatternCache(true);
    //listPatterns();
  }, [])

  const newPatternTemplate = {
    "patternId": "",
    "patternName": "New",
    "rules": {
        "patternRequired": [],
        "patternTranslationAllowed": false,
        "patternRotationAllowed": false,
        "numberOfBingosNeeded": 1,
        "horizontalAllowed": false,
        "verticalAllowed": false,
        "diagonalAllowed": false,
        "fourCornersAllowed": false,
        "fourCornersRequired": false,
        "parallelLinesAllowed": false,
        "parallelLinesRequired": false,
        "perpendicularLinesAllowed": false,
        "perpendicularLinesRequired": false
    }
};
  
  // function old_listPatterns() {
  //   return fetch(`https://api.b4.bingo/patterns/20220406`)
  //   .then(res => res.json())
  //   .then((pList) => {

  //       var patterns = [];
  //       for (let index = 0; index < pList.length; index++) {
  //         var pattern = pList[index].name;
  //         patterns.push(pattern);
  //         console.log(pattern);
  //       }

  //       setPatternList(patterns);
      
  //       return patterns;
  //     })
  //     .catch(() => {return [];});
  // }


  function parseGameInfoData(data, startingRoundNumber = 3, gameName = "")
  {
    var dataOut = JSON.parse(JSON.stringify(data));

    if (data.payload.gameName === undefined)
    {
      var index = 0;
      var patternList = [];
      for (var pattern of data.payload)
      {
        var roundNumber = startingRoundNumber + index++;
        var pObj = {
          "roundNumber": roundNumber,
          "patternId": pattern
        }
        patternList.push(pObj);
      }

      dataOut.payload = {};
      dataOut.payload.gameName = gameName;
      dataOut.payload.patterns = patternList;
    }
    dataOut.payload.patterns = dataOut.payload.patterns.sort((firstRound, secondRound) => firstRound.roundNumber - secondRound.roundNumber);
    return dataOut;
  }
  

  function listPatterns(yyyymmdd = '') {
    //var yyyymmdd = '20220420';
    var url = 'https://api.b4.bingo/games';
    if (yyyymmdd !== '')
      url = `${url}/${yyyymmdd}`;
    
    fetch(url)
    .then(res => res.json())
    .then((pList) => {
      var parsed = parseGameInfoData(pList);      
      setPatternList(parsed.payload.patterns);
      return parsed.payload.patterns;
    })
    .catch((error) => {
      console.log(error);
      setPatternList([]);
      return [];
    });
  }

  function getAllPatterns() {

    var data = [];
    var cachedPatterns = getPatternCache().then((data) => {console.log("mehhhh", data)});
    return cachedPatterns.sort((firstObj, secondObj) => firstObj.name <= secondObj.name);

    // var url = 'https://api.b4.bingo/patterncache';
    
    // fetch(url)
    // .then(res => res.json())
    // .then((pList) => {
    //   var parsed = parseGameInfoData(pList);
    //   setPatternList(parsed.payload.patterns);
    //   return parsed.payload.patterns;
    //   })
    // .catch((error) => {
    //   console.log(error);
    //   setPatternList([]);
    //   return [];
    // });
  }

  function getPatternCache(forceFlag = false) {

    return [];

    if (patternCache !== null && forceFlag === false)
    {
      console.log("returning cached pattern cache value");
      return patternCache;
    }

    //var pCache = [{"hash":"25d8905c13f004d5eccb93b0c766d4793f395aa2","name":"Sexy Dress"},{"hash":"37f59573527565f44bba7465a57ffaecead4b8dc","name":"High Heels"},{"hash":"5c02b3018d903224fb5494de69c7c76f6d3e3e31","name":"Four Stamps"},{"hash":"a9196c77e784e29e093458dc7c55f28e2e759009","name":"Purse"},{"hash":"afe0bbae8f59e044f03db9ee6475451549f2cb30","name":"Shopping Cart"},{"hash":"dc87811bec2022474ad716de1d5adec74c90415f","name":"Two X's"}];
    return fetch(`https://api.b4.bingo/patterncache`)
          .then(res => res.json())
          .then((data) => {
            setPatternCache(data);
            return data;
          })
          .catch(() => {
            console.log("error getting pattern cache...");
            return [];
          });
  }


  function onPatternSave(patternData){
    console.log('Save Clicked... card state = ', patternData);
    savePattern(patternData);
  }

  function savePattern(patternData) {

    var templateData = JSON.parse(JSON.stringify(newPatternTemplate));
    templateData.patternName = patternData.patternTitle;
    templateData.patternId = patternData.patternHash;
    templateData.rules.patternRequired = patternData.selectedCells;

    console.log(templateData);

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(templateData)
    };

    return fetch(`https://api.b4.bingo/pattern/pattern_${patternData.patternHash}`, requestOptions)
    .then(res => res.json())
    .then((data) => {
        console.log("data = ", data);

        if (data.error)
        {
          console.log("Save error!", data.errorMessage);
          //return this.selectedCells;
        }
        else
        {
          console.log("All good!");
          //this.selectedCells = data.payload.rules.patternRequired;
          //var title = data.payload.patternName;
          //this.setState({patternTitle: title}, () => {
          //console.log("patternTitle updated", this.state.patternTitle);
          //this.forceUpdate();
          //  });
        }
      })
      .catch(() => {return [];});
  }

  //console.log(listPatterns());

  function Home() {
    return (
      <>
      <div className="row">
      <h1>Welcome!</h1>
      <p>This site is designed to improve your bingo playing experience and is a work in process. We've got some cool things planned and welcome your feedback along the way. Feel free to bookmark it for future games.</p>
      <Link to="/rules" className="btn btn-block my-1 btn-success">Read the rules</Link>
      <Link to="/patterns" className="btn btn-block my-1 btn-success">See the patterns</Link>
      </div>

      <div className="row mt-5">
        <div className='col-12'>
          <h3 className="mb-0 mb-4">Follow us on Facebook to keep up with the weekly events and themes!</h3>
          <ul className="fa-ul">
            <li><span className="fa-li"><i className="fab fa-facebook"></i></span>
              <a target="_blank" href="https://www.facebook.com/groups/653536651734055">Sosshial B4 Bingo</a>
            </li>
            <li><span className="fa-li"><i className="fab fa-facebook"></i></span>
              <a target="_blank" href="https://www.facebook.com/JenningsStreetPublicHouse">Jennings Street Public House</a>
            </li>
          </ul>

          {/* <Button type="button" className="btn btn-labeled" href="https://www.facebook.com/groups/653536651734055">Sosshial B4 Bingo</Button>
          <Button type="button" className="btn" variant="outline-dark" href="https://www.facebook.com/JenningsStreetPublicHouse">Jennings Street Public House</Button> */}
        </div>    
      </div>

      </>
    );
  }

  function Patterns() {

    if (patternList === undefined || patternList.length === 0)
    {
      listPatterns();
    }
    
    return (
      <>
        <div className="row">
          {
            patternList.map((item, index) => {
              return (
                <div key={index} className="col-sm text-center">
                  <div className="my-5">
                    <BingoCard4 gameNumber={item.roundNumber} patternId={item.patternId} editable={false} getPatternCacheHandler={getPatternCache}></BingoCard4>
                  </div>
                </div>)
            })
          }
        </div>
      </>
    )
    
  }

  function CreateNewPattern() {
    return (
      <>
      <div className="row">
        <div className="col">
          <BingoCard4 gameNumber="" patternId="" editable={true} patternSaveHandler={onPatternSave} getPatternCacheHandler={getPatternCache}></BingoCard4>   
        </div>
      </div>
      </>
    );
  }

  function CreateNewGame() {
    return (
      <>
      <div className="row">
        <div className="col">
          <BingoCard4 gameNumber="" patternId="" editable={false} patternSaveHandler={onPatternSave} getPatternCacheHandler={getPatternCache}></BingoCard4>   
        </div>
      </div>
      </>
    );
  }

  //   function Load() {
  //     return (
  //       <>
  //        <div id="loader" className="mt-4">
  //         <div className="container-fluid text-center">
  //           <h1 className="display-1">Sosshial B4 Bingo!</h1>
  //           <img className="loader_image" src="logo_1024.png"></img>
  //         </div>
  //       </div>
  //       </>
  //     );
  // }
  
  function Rules() {
    return (
      <>
      <h1>Rules</h1>
      <p>These are the rules... read them, know them.</p>
      <Container className="font-comfortaa">
      <Accordion defaultActiveKey="-1">

        <Accordion.Item eventKey="0">
          <Accordion.Header><span className="ruleTitle">One card per round</span></Accordion.Header>
          <Accordion.Body className="ruleBody">
              <p>You have five cards in front of you for the five rounds we will play. You can only play one card at a time.<br/><br/>The only exception to this rule is if you have won an extra card and are opting to use it.</p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header><span className="ruleTitle">Four corners do not count unless otherwise stated</span></Accordion.Header>
          <Accordion.Body className="ruleBody">
              <p>'nuff said.</p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header><span className="ruleTitle">No repeats... I repeat: No repeats</span></Accordion.Header>
          <Accordion.Body className="ruleBody">
              <p>Keep an ear out because each ball will only be called once.</p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header><span className="ruleTitle">No false bingos</span></Accordion.Header>
          <Accordion.Body className="ruleBody">
          <p className="mb-0">A player found guilty of calling a false bingo may choose from one of the following penalties:</p>
                <ul className="mt-2">
                    <li>Do push-ups</li>
                    <li>Arm wrestle</li>
                </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header><span className="ruleTitle">Tie-breaker for multiple winners</span></Accordion.Header>
          <Accordion.Body className="ruleBody">
          <p className="mb-0">In the event of multiple winners during a round, a chug-off will take place to determine who wins the prize for the round.</p>
                <ul className="mt-2">
                    <li>No tagging-in others to drink for you</li>
                    <li>If you don’t like beer or are allergic or can’t drink for other reasons, all potential winners must agree on water instead of beer. If an agreement can’t be made, the round will continue on to the next eligible bingo called.<br /></li>
                    <li>First person to finish their drink (without dribbling) and return the glass to the table will be declared the winner</li>
                </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header><span className="ruleTitle">Social balls deserve to be celebrated</span></Accordion.Header>
          <Accordion.Body className="ruleBody">
          <p className="mb-0">I18 and O69 have special status as &quot;social balls&quot;. When a social ball is called, celebrate by performing the following ritual:</p>
                <ul className="mt-2">
                    <li>Raise your glass</li>
                    <li>Say &quot;Social!&quot;</li>
                    <li>Take a sip</li>
                </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header><span className="ruleTitle">Have fun and tip your bartenders well</span></Accordion.Header>
          <Accordion.Body className="ruleBody">
            
          </Accordion.Body>
        </Accordion.Item>
        
      </Accordion>
    </Container>
        
      </>
    );
  }

  function getSearchItemData(sampleName, type){
    console.log(`Getting search data (${sampleName})`);
    var data = mockDataHelper.getMockData(sampleName);

    if (data.error === false)
    {
      switch (type) {
        case "artists":
          return data.payload.artists.items;
        case "tracks":
          return data.payload.tracks.items;
        default:
          return [];
      }
    }

    return [];
  }

  function SpotifySearch() {
    return (
      <>
        <SpotifySearchResultList searchTerm="bad fish" trackList={getSearchItemData('badfish', 'tracks')}></SpotifySearchResultList>
      </>
    );
  }

  return (
    <div className="App">
      <BingoNav></BingoNav>
      <Container className="mb-5">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/patterns" element={<Patterns />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/create-new-pattern" element={<CreateNewPattern />} />
          <Route path="/create-new-game" element={<CreateNewGame />} />
          <Route path="/spotify" element={<SpotifySearch />} />
        </Routes>
      </Container>
      
    </div>
  );

}




export default App;
