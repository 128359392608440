import React, { Component } from 'react';

export class SpotifySearchTrackItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div id={`id_${this.props.trackId}`} className="row mb-1 trackRow">
                    <div className="col"><img className="float-start trackImage" src={this.props.albumImageUrl ?? "https://dummyimage.com/300/000/fff.png&amp;text=Not+Pictured"} />
                        <div className="float-start trackInfoDiv">
                            <h1 className={`trackTitle ${this.props.scrollTitle === true ? 'scrolling' : ''}`}>{this.props.trackTitle}</h1>
                            <h2 className={`artistName ${this.props.scrollArtist === true ? 'scrolling' : ''}`}>{this.props.artistName}</h2>
                            <span className={`badge rounded-pill bg-dark ${this.props.explicit !== true ? 'd-none' : ''}`}>EXPLICIT</span>
                        </div>
                        <div className="d-flex justify-content-end align-items-center trackVoteInfoDiv">
                            <div className="text-center">
                                <h1 className="display-2" onClick={this.props.onAddTrackClick}><i className="far fa-thumbs-up text-muted"></i></h1>
                                <h6><span className={`badge rounded-pill ${this.props.votes === 0 ? 'bg-secondary' : 'bg-success'} trackVoteCountBadge`}>{this.props.votes}</span></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}
export default SpotifySearchTrackItem;