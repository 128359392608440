import React, { Component, useState, useEffect, useRef } from 'react';
import { SpotifySearchTrackItem } from '../SpotifySearchItem/SpotifySearchTrackItem';
import { SpotifySearchArtistItem } from '../SpotifySearchItem/SpotifySearchArtistItem';
import './SpotifySearchResultList.css';
import SpotifySearchInput from '../SpotifySearchInput/SpotifySearchInput';
//import SpotifySearchInput from './SpotifySearchInput';
//import SpotifySearchItem from './SpotifySearchItem';

class SpotifySearchResultList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trackList: props.trackList
        };
    }

    getArtistWidth(text){
        var h = document.createElement("h2");
        h.classList.add("artistName");
        h.text = text;
        var font = this.getCanvasFontSize(h);
        h = undefined;
        return this.getTextWidth(text, font);
    }

    getTrackWidth(text){
        var h = document.createElement("h1");
        h.classList.add("trackTitle");
        h.text = text;
        var font = this.getCanvasFontSize(h);
        h = undefined;
        return this.getTextWidth(text, font);
    }

    getTextWidth(text, font) {
        // re-use canvas object for better performance
        const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }
    
    getCssStyle(element, prop) {
        return window.getComputedStyle(element, null).getPropertyValue(prop);
    }
    
    getCanvasFontSize(el = document.body) {
        const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal';
        const fontSize = this.getCssStyle(el, 'font-size') || '16px';
        const fontFamily = this.getCssStyle(el, 'font-family') || 'Times New Roman';
        
        return `${fontWeight} ${fontSize} ${fontFamily}`;
    }

    handleAddTrackClick(itemId) {
        console.log("clicked!!", itemId);
        //this.props.trackList

        var trackListCopy = JSON.parse(JSON.stringify(this.state.trackList));

        for (let index = 0; index < trackListCopy.length; index++) {
            const track = trackListCopy[index];
            if (track.id === itemId)
            {
                if (track.votes === undefined)
                {
                    track.votes = 0;
                }
                track.votes++;
            }
        }

        this.setState((state) => ({
            trackList: trackListCopy
        }));
        
    }

    render() {
        // define a variable for search results
        // var searchresults = this.props.artistList.map(function(artistItem) {
        //   return (
        //       <SpotifySearchItemArtist key={artistItem.id} name={artistItem.name} image={artistItem.images[0]?.url ?? "https://dummyimage.com/300/000/fff.png&text=Not+Pictured"} />
        //   );
        // });

        var searchresults = this.state.trackList.map((trackItem) => {
            return (
                <SpotifySearchTrackItem
                    key={trackItem.id}
                    scrollTitle={this.getTrackWidth(trackItem.name) >= 150 ? true : false}
                    scrollArtist={this.getArtistWidth(trackItem.artists[0].name) >= 150 ? true : false}
                    trackId={trackItem.id}
                    trackTitle={trackItem.name} 
                    artistName={trackItem.artists[0].name} 
                    explicit={trackItem.explicit}
                    albumImageUrl={trackItem.album?.images[0]?.url ?? "https://dummyimage.com/300/000/fff.png&text=Not+Pictured"} 
                    trackData={trackItem}
                    onAddTrackClick={this.handleAddTrackClick.bind(this,trackItem.id)}
                    votes={trackItem.votes}
                    />
            );
        });

        // return the following code

        try {
            //console.log("rendering...");
            return (
                <div className="spotify-search">
                    {/* <SpotifySearchInput searchSpotify={this.props.searchSpotify} /> */}
                    <ul className="spotify-list">
                        {searchresults}
                    </ul>
                </div>
            );
        } catch (error) {
            
        } finally {
            console.log("rendered!");
        }

        
    }
}

export default SpotifySearchResultList;