import React, { Component } from 'react';
//import PropTypes from 'prop-types';
//import BingoCell from '../BingoCell/BingoCell';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import './BingoCard4.css';
import '../BingoCell/BingoCell.css';
import hash from 'object-hash';

class BingoCardCell extends React.Component {
  constructor(props){
      super();
      this.state = {
          col: props.col,
          row: props.row,
          selected: props.selected,
          editable: props.editable
      };
  }

  toggleSelection() {
    //console.log("cell editable:", this.state.editable);
    if (this.state.editable)
    {
      this.setState({selected: !this.state.selected}, () => {
        this.props.updateHandler(this.state);
      });
    }
  }

  render() {
    if (this.props.row === 0)
    {
      return (
        <>
          <div className="bingo-header-cell-div" style={{width: this.props.cellSize, height: this.props.cellSize, fontSize: this.props.cellSize / 12}}>
            <div className="bingo-header-label" style={{width: this.props.cellSize - 5, height: this.props.cellSize - 5}}>
              {this.props.col}
            </div>
          </div>
        </>
      );
    }
    else
    {
      return (
        <>
          <div id={this.props.cellId} onClick={() => { this.toggleSelection(); }} className="bingo-cell-div" style={{width: this.props.cellSize, height: this.props.cellSize}}>
            <div className={`${this.state.selected ? "bingo-cell-selected" : "bingo-cell-empty"}`} style={{width: this.props.cellSize - 10, height: this.props.cellSize - 10}}></div>
          </div>
        </>
      );
    }
  }
}

class BingoCard4 extends React.Component {
  
  constructor(props){
      super(props);
      this.cardHeight = 250;
      this.cardWidth = 250;
      this.selectedCells = [];
      this.state = {
        //selectedCells: [],
        patternTitle: "",
        cellSize: 50,
        cells: this.initializeCells(),
        //patternHash: "",
        editable: this.props.editable,
        gameNumber: this.props.gameNumber,
        isUnsaved: true
      };
      this.calculatedHash = function() { return hash(this.selectedCells) };
  }

  componentDidMount() {
    //console.log("Mounted");
    this.setState({selectedCells: this.selectedCells}, () => {

      //console.log("selectedCells state set:", this.selectedCells);
      this.loadPattern(this.props.patternId)
      .then(()=>{
        //this.setState({cells: this.initializeCells()}, () => {
        //console.log("setting state done!");
        //this.updateCellSelections();
        this.setState({cells: []}, ()=>{
          //console.log("cell state cleared!", this.cells);

          this.setState({cells: this.initializeCells()}, ()=>{
            //console.log("cell state reset!", this.cells);
          });
        });
        //console.log("Done!");
    });

    });
    //this.selectedCells = [];
    

    
    //console.log("Set tp = ", this.selectedCells);
    
  }


  componentWillUnmount() {

  }


  onCellChange = (cellState) => {
    //console.log(cellState);

    var currentState = JSON.parse(JSON.stringify(this.state.cells));
    if (this.state.editable)
    {
      for (let index = 0; index < currentState.length; index++) {
        var myCell = currentState[index];
        if (myCell.c === cellState.col && myCell.r === cellState.row)
        {
          myCell.s = cellState.selected;
        }
      }

      this.setState({cells: currentState}, ()=> {
        //console.log("Updated!", this.selectedCells);
        //this.forceUpdate();
        this.updateSelectedCells();
      });
    }
    

  }

  updateSelectedCells() {

    var selectedCells = [];
    for (let index = 0; index < this.state.cells.length; index++) {
      var cell = this.state.cells[index];
      if (cell.s)
      {
        selectedCells.push(`${cell.c}${cell.r}`);
      }
    }
    this.selectedCells = selectedCells;

    //this.setState({selectedCells: selectedCells},()=>{
      //console.log("Selected cells updated:", this.state.selectedCells);
      //console.log("Selected cells updated:", this.selectedCells);
      //console.log(hash(this.state.selectedCells));
      //this.setState({patternHash: hash(this.selectedCells)}, ()=>{

      var patternName = this.checkPatternHash();
      if (patternName !== "")
      {
        this.setState({patternTitle: patternName});
        this.setState({isUnsaved: false});
      }
      else
      {
        this.setState({patternTitle: "[New Pattern]"});
        this.setState({isUnsaved: true});
      }

      //});
    //});
  }

  initializeCells() {

    var cells = [];
    const columns = ["B","I","N","G","O"];
    const rows = [0,1,2,3,4,5];

    var patternArray = [];
    
    if (this.selectedCells !== undefined)
      patternArray = this.selectedCells;

    //console.log("Pattern Array", patternArray);
    rows.forEach((row)=>{
      columns.forEach((col) => {
        var myCell = {
          "c": col,
          "r": row,
          "s": patternArray.indexOf(`${col}${row}`) >= 0 ? true : false
        }
        cells.push(myCell);
      });
    }); 

    return cells;
  
  }


  loadPattern(patternId) {
    //console.log("loading pattern " + patternId);
    //var pCache = this.props.getPatternCacheHandler();
    //console.log(pCache);

    if (!patternId.toString().toLowerCase().startsWith('pattern_'))
    {
      patternId = `pattern_${patternId}`;
    }

    return fetch(`https://api.b4.bingo/pattern/${patternId}`)
    .then(res => res.json())
    .then((data) => {
        //console.log("data = ", data);

        if (data.error)
        {
            console.log("loadPattern error:", data.errorMessage);
            this.selectedCells = [];
            this.setState({patternTitle: ""});
            //return this.selectedCells;
        }
        else
        {
          this.selectedCells = data.payload.rules.patternRequired;
          var title = data.payload.patternName;
          this.setState({patternTitle: title}, () => {
            //console.log("patternTitle updated", this.state.patternTitle);
            //this.forceUpdate();
          });
        }
      })
      .catch(() => {return [];});
  }

  checkPatternHash() {
    
    var pCache = this.props.getPatternCacheHandler(true);
   
    // var pCache = [{"hash":"25d8905c13f004d5eccb93b0c766d4793f395aa2","name":"Sexy Dress"},{"hash":"37f59573527565f44bba7465a57ffaecead4b8dc","name":"High Heels"},{"hash":"5c02b3018d903224fb5494de69c7c76f6d3e3e31","name":"Four Stamps"},{"hash":"a9196c77e784e29e093458dc7c55f28e2e759009","name":"Purse"},{"hash":"afe0bbae8f59e044f03db9ee6475451549f2cb30","name":"Shopping Cart"},{"hash":"dc87811bec2022474ad716de1d5adec74c90415f","name":"Two X's"}];
    
    // for (var i = 0; i < pCache.length; i++) {
    //   if (pCache[i].hash === pHash) {
    //     return pCache[i].name;
    //   }
    // }

    var pHash = this.calculatedHash();

    return fetch(`https://api.b4.bingo/pattern/pattern_${pHash}`)
    .then(res => res.json())
    .then((data) => {
        console.log("data = ", data);

        if (data.error)
        {
            //console.log("loadPattern error:", data.errorMessage);
            //this.selectedCells = [];
            //return this.selectedCells;
            this.setState({patternTitle: ""}, () => {
              console.log("patternTitle updated", this.state.patternTitle);
              //this.forceUpdate();
              return this.state.patternTitle;
            });
        }
        else
        {
          this.selectedCells = data.payload.rules.patternRequired;
          var title = data.payload.patternName;
          this.setState({patternTitle: title}, () => {
            console.log("patternTitle updated", this.state.patternTitle);
            //this.forceUpdate();
            return this.state.patternTitle;
          });
        }

        //return data;
      })
      .catch(() => {return "";});
  }

  handleSavePatternClick() {
    if (this.state.editable)
    {
      // this.setState({selected: !this.state.selected}, () => {
      //   this.props.updateHandler(this.state);
      // });

      //this.updateSelectedCells();
      var patternName = this.state.patternTitle;

      if (this.state.patternTitle === "")
      {
        patternName = prompt("Please enter a name for this pattern", "");
        if (patternName !== "")
        {
          this.setState({patternTitle: patternName.trim()});
        }
        else
        {
          alert("No name entered... aborting save!");
          return;
        }
      }

      var patternData = {
        patternTitle: patternName,
        patternHash: this.calculatedHash(),
        selectedCells: this.selectedCells,
      }

      console.log("saving pattern", patternData);
      this.props.patternSaveHandler(patternData);
      
    }
  }

  handleRenamePatternClick() {
    if (this.state.editable)
    {
      this.setState({patternTitle: ""}, () =>{
        this.handleSavePatternClick();
      });
    }
  }

  handleClearPatternClick() {
    
    
    if (this.state.editable)
    {
      console.log("clear clicked");
      this.selectedCells = [];
      //this.setState({patternTitle: "meh"});
      this.initializeCells();
      this.updateSelectedCells();
    }
  }

  savePattern(hash) {
    console.log(hash);

    return;
    var patternId = '';
    return fetch(`https://api.b4.bingo/pattern/${patternId}`)
    .then(res => res.json())
    .then((data) => {
        console.log("data = ", data);

        if (data.error)
        {
            console.log("loadPattern error:", data.errorMessage);
            this.selectedCells = [];
            //return this.selectedCells;
        }
        else
        {
          this.selectedCells = data.payload.rules.patternRequired;
          var title = data.payload.patternName;
          this.setState({patternTitle: title}, () => {
            console.log("patternTitle updated", this.state.patternTitle);
            //this.forceUpdate();
          });
        }
      })
      .catch(() => {return [];});
  }

  render() {
    if (this.state.editable === null || this.state.editable === false)
    {
      return <>
        <h2 className={`mb-3 ${this.state.gameNumber === '' ? 'd-none' : ''}`}>{`Game ${this.state.gameNumber}`}: <br/>
        <small className="text-muted">{this.state.patternTitle}</small>
          
        </h2>
        {/* <h3 className={`display-5 ${this.state.gameNumber === '' ? 'd-none' : ''}`}>{`Game ${this.state.gameNumber}`}</h3>
        <h4 className={`display-6 mb-3 ${this.state.gameNumber === '' ? 'd-none' : ''}`}>{this.state.patternTitle}</h4> */}
        <div className="bingo-card" style={{ width: this.cardWidth, height: this.cardHeight }}>
          {
            this.state.cells.map((cellKey, cellIndex) => {
              return (<BingoCardCell
                key={`${this.state.gameNumber}_${cellIndex}`}
                col={cellKey.c}
                row={cellKey.r}
                selected={cellKey.s}
                cellSize={this.state.cellSize}
                updateHandler={this.onCellChange}
                editable={this.state.editable}></BingoCardCell>)
            })
          }
        </div>
      </>
    }
    else
    {
      return <>
      {/* <h1>Pattern: {this.state.patternTitle}</h1> */}
        <ButtonGroup size="lg" className={`m-3 ${this.state.editable ? '' : 'd-none'}`}>
          <Button variant="success" onClick={() => { this.handleSavePatternClick(); }}>Save</Button>
          <Button variant="danger" onClick={() => { this.handleClearPatternClick(); }}>Clear</Button>
          <Button variant="primary" onClick={() => { this.handleRenamePatternClick(); }}>Rename</Button>
        </ButtonGroup>
        
        <div className="bingo-card" style={{ width: this.cardWidth, height: this.cardHeight }}>
          {
            this.state.cells.map((cellKey, cellIndex) => {
              return (<BingoCardCell
                        key={`${this.state.gameNumber}_${cellIndex}`} 
                        col={cellKey.c} 
                        row={cellKey.r} 
                        selected={cellKey.s} 
                        cellSize={this.state.cellSize} 
                        updateHandler={this.onCellChange} 
                        editable={this.state.editable}>
                      </BingoCardCell>)
            })
          }
        </div>
        {/* <br/><span>{JSON.stringify(this.state.selectedCells)}</span>
        <br/><span>{JSON.stringify(this.state.patternHash)}</span> */}
        </>
    }
  }
}



export default BingoCard4;