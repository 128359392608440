var MockDataHelper = (function () {

    const badfish = {
        "error": false,
        "message": "",
        "payload": 
            {
                "artists": {
                    "href": "https://api.spotify.com/v1/search?query=bad+fish&type=artist&include_external=audio&market=US&offset=0&limit=20",
                    "items": [
                        {
                            "external_urls": {
                                "spotify": "https://open.spotify.com/artist/0Lq022fcanpb4atRpZakk9"
                            },
                            "followers": {
                                "href": null,
                                "total": 3052
                            },
                            "genres": [
                                "reggae rock"
                            ],
                            "href": "https://api.spotify.com/v1/artists/0Lq022fcanpb4atRpZakk9",
                            "id": "0Lq022fcanpb4atRpZakk9",
                            "images": [
                                {
                                    "height": 640,
                                    "url": "https://i.scdn.co/image/ab67616d0000b273031ec5e4581fe2de9f3d85c0",
                                    "width": 640
                                },
                                {
                                    "height": 300,
                                    "url": "https://i.scdn.co/image/ab67616d00001e02031ec5e4581fe2de9f3d85c0",
                                    "width": 300
                                },
                                {
                                    "height": 64,
                                    "url": "https://i.scdn.co/image/ab67616d00004851031ec5e4581fe2de9f3d85c0",
                                    "width": 64
                                }
                            ],
                            "name": "Badfish",
                            "popularity": 21,
                            "type": "artist",
                            "uri": "spotify:artist:0Lq022fcanpb4atRpZakk9"
                        },
                        {
                            "external_urls": {
                                "spotify": "https://open.spotify.com/artist/30sRaQeFHjRnxwk2snq1kN"
                            },
                            "followers": {
                                "href": null,
                                "total": 1
                            },
                            "genres": [],
                            "href": "https://api.spotify.com/v1/artists/30sRaQeFHjRnxwk2snq1kN",
                            "id": "30sRaQeFHjRnxwk2snq1kN",
                            "images": [],
                            "name": "The Bad Fish",
                            "popularity": 0,
                            "type": "artist",
                            "uri": "spotify:artist:30sRaQeFHjRnxwk2snq1kN"
                        },
                        {
                            "external_urls": {
                                "spotify": "https://open.spotify.com/artist/4G1zBVKuB3ypE2QSpEXOiE"
                            },
                            "followers": {
                                "href": null,
                                "total": 2
                            },
                            "genres": [],
                            "href": "https://api.spotify.com/v1/artists/4G1zBVKuB3ypE2QSpEXOiE",
                            "id": "4G1zBVKuB3ypE2QSpEXOiE",
                            "images": [
                                {
                                    "height": 640,
                                    "url": "https://i.scdn.co/image/ab6761610000e5ebc13b05e0e4a7bd892cae63a7",
                                    "width": 640
                                },
                                {
                                    "height": 320,
                                    "url": "https://i.scdn.co/image/ab67616100005174c13b05e0e4a7bd892cae63a7",
                                    "width": 320
                                },
                                {
                                    "height": 160,
                                    "url": "https://i.scdn.co/image/ab6761610000f178c13b05e0e4a7bd892cae63a7",
                                    "width": 160
                                }
                            ],
                            "name": "badfish",
                            "popularity": 0,
                            "type": "artist",
                            "uri": "spotify:artist:4G1zBVKuB3ypE2QSpEXOiE"
                        },
                        {
                            "external_urls": {
                                "spotify": "https://open.spotify.com/artist/3zjIEg5a2vlZRZDAO3l6GC"
                            },
                            "followers": {
                                "href": null,
                                "total": 4
                            },
                            "genres": [],
                            "href": "https://api.spotify.com/v1/artists/3zjIEg5a2vlZRZDAO3l6GC",
                            "id": "3zjIEg5a2vlZRZDAO3l6GC",
                            "images": [
                                {
                                    "height": 640,
                                    "url": "https://i.scdn.co/image/ab67616d0000b273b2e15f807c713fd3b0914456",
                                    "width": 640
                                },
                                {
                                    "height": 300,
                                    "url": "https://i.scdn.co/image/ab67616d00001e02b2e15f807c713fd3b0914456",
                                    "width": 300
                                },
                                {
                                    "height": 64,
                                    "url": "https://i.scdn.co/image/ab67616d00004851b2e15f807c713fd3b0914456",
                                    "width": 64
                                }
                            ],
                            "name": "Tony Wardle & the Bad Fish",
                            "popularity": 0,
                            "type": "artist",
                            "uri": "spotify:artist:3zjIEg5a2vlZRZDAO3l6GC"
                        }
                    ],
                    "limit": 20,
                    "next": null,
                    "offset": 0,
                    "previous": null,
                    "total": 4
                },
                "tracks": {
                    "href": "https://api.spotify.com/v1/search?query=bad+fish&type=track&include_external=audio&market=US&offset=0&limit=20",
                    "items": [
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/02VqlfUtNMogzTh1lckGOF"
                                },
                                "href": "https://api.spotify.com/v1/albums/02VqlfUtNMogzTh1lckGOF",
                                "id": "02VqlfUtNMogzTh1lckGOF",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273e6f11f4c160143e5efb97651",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02e6f11f4c160143e5efb97651",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851e6f11f4c160143e5efb97651",
                                        "width": 64
                                    }
                                ],
                                "name": "Jah Won't Pay The Bills",
                                "release_date": "1991",
                                "release_date_precision": "year",
                                "total_tracks": 20,
                                "type": "album",
                                "uri": "spotify:album:02VqlfUtNMogzTh1lckGOF"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 188085,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USGA19244450"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/4paJ10JPNCyOaLtz2cY928"
                            },
                            "href": "https://api.spotify.com/v1/tracks/4paJ10JPNCyOaLtz2cY928",
                            "id": "4paJ10JPNCyOaLtz2cY928",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish",
                            "popularity": 65,
                            "preview_url": null,
                            "track_number": 2,
                            "type": "track",
                            "uri": "spotify:track:4paJ10JPNCyOaLtz2cY928"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/1By9QBFnjZAoI83BZppHlt"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/1By9QBFnjZAoI83BZppHlt",
                                        "id": "1By9QBFnjZAoI83BZppHlt",
                                        "name": "Billy Currington",
                                        "type": "artist",
                                        "uri": "spotify:artist:1By9QBFnjZAoI83BZppHlt"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/1gbcNmTEXs2GYktvarZuli"
                                },
                                "href": "https://api.spotify.com/v1/albums/1gbcNmTEXs2GYktvarZuli",
                                "id": "1gbcNmTEXs2GYktvarZuli",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b27342c902796574bddbe0d3082e",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e0242c902796574bddbe0d3082e",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d0000485142c902796574bddbe0d3082e",
                                        "width": 64
                                    }
                                ],
                                "name": "Enjoy Yourself",
                                "release_date": "2010-01-01",
                                "release_date_precision": "day",
                                "total_tracks": 11,
                                "type": "album",
                                "uri": "spotify:album:1gbcNmTEXs2GYktvarZuli"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/1By9QBFnjZAoI83BZppHlt"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/1By9QBFnjZAoI83BZppHlt",
                                    "id": "1By9QBFnjZAoI83BZppHlt",
                                    "name": "Billy Currington",
                                    "type": "artist",
                                    "uri": "spotify:artist:1By9QBFnjZAoI83BZppHlt"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 182880,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USUM71017368"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/2eUPwmBnjYDCtF25ibupuk"
                            },
                            "href": "https://api.spotify.com/v1/tracks/2eUPwmBnjYDCtF25ibupuk",
                            "id": "2eUPwmBnjYDCtF25ibupuk",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Bad Day Of Fishin'",
                            "popularity": 36,
                            "preview_url": null,
                            "track_number": 8,
                            "type": "track",
                            "uri": "spotify:track:2eUPwmBnjYDCtF25ibupuk"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/6hrOM3yfx3SEtARzDWMikb"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/6hrOM3yfx3SEtARzDWMikb",
                                        "id": "6hrOM3yfx3SEtARzDWMikb",
                                        "name": "DENM",
                                        "type": "artist",
                                        "uri": "spotify:artist:6hrOM3yfx3SEtARzDWMikb"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/0MgiASaKPnKqEYQiobt7ix"
                                },
                                "href": "https://api.spotify.com/v1/albums/0MgiASaKPnKqEYQiobt7ix",
                                "id": "0MgiASaKPnKqEYQiobt7ix",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b2738137cf4f5573ecb33c67820b",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e028137cf4f5573ecb33c67820b",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d000048518137cf4f5573ecb33c67820b",
                                        "width": 64
                                    }
                                ],
                                "name": "Slum Beach Denny",
                                "release_date": "2021-05-14",
                                "release_date_precision": "day",
                                "total_tracks": 16,
                                "type": "album",
                                "uri": "spotify:album:0MgiASaKPnKqEYQiobt7ix"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/6hrOM3yfx3SEtARzDWMikb"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/6hrOM3yfx3SEtARzDWMikb",
                                    "id": "6hrOM3yfx3SEtARzDWMikb",
                                    "name": "DENM",
                                    "type": "artist",
                                    "uri": "spotify:artist:6hrOM3yfx3SEtARzDWMikb"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 185644,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "QZ5FN2179742"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/3nnZkIipqrxJVBTHOwMC7X"
                            },
                            "href": "https://api.spotify.com/v1/tracks/3nnZkIipqrxJVBTHOwMC7X",
                            "id": "3nnZkIipqrxJVBTHOwMC7X",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish",
                            "popularity": 25,
                            "preview_url": "https://p.scdn.co/mp3-preview/64b1fb78e7364478957f46228b182baf96f6b9d8?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 11,
                            "type": "track",
                            "uri": "spotify:track:3nnZkIipqrxJVBTHOwMC7X"
                        },
                        {
                            "album": {
                                "album_type": "compilation",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0LyfQWJT6nXafLPZqxe9Of"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0LyfQWJT6nXafLPZqxe9Of",
                                        "id": "0LyfQWJT6nXafLPZqxe9Of",
                                        "name": "Various Artists",
                                        "type": "artist",
                                        "uri": "spotify:artist:0LyfQWJT6nXafLPZqxe9Of"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/21lHOo0woYbIN92GcprzQi"
                                },
                                "href": "https://api.spotify.com/v1/albums/21lHOo0woYbIN92GcprzQi",
                                "id": "21lHOo0woYbIN92GcprzQi",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273e95429d7c6dbb7e238bcd680",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02e95429d7c6dbb7e238bcd680",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851e95429d7c6dbb7e238bcd680",
                                        "width": 64
                                    }
                                ],
                                "name": "The Big E: A Salute to Steel Guitarist Buddy Emmons",
                                "release_date": "2013-08-20",
                                "release_date_precision": "day",
                                "total_tracks": 16,
                                "type": "album",
                                "uri": "spotify:album:21lHOo0woYbIN92GcprzQi"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/4YLtscXsxbVgi031ovDDdh"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/4YLtscXsxbVgi031ovDDdh",
                                    "id": "4YLtscXsxbVgi031ovDDdh",
                                    "name": "Chris Stapleton",
                                    "type": "artist",
                                    "uri": "spotify:artist:4YLtscXsxbVgi031ovDDdh"
                                },
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/7b10aYN6jh7Dai5c4tshD9"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/7b10aYN6jh7Dai5c4tshD9",
                                    "id": "7b10aYN6jh7Dai5c4tshD9",
                                    "name": "Roosevelt Collier",
                                    "type": "artist",
                                    "uri": "spotify:artist:7b10aYN6jh7Dai5c4tshD9"
                                },
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/2sbjE1Y86SQKQo9fYHoiqE"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/2sbjE1Y86SQKQo9fYHoiqE",
                                    "id": "2sbjE1Y86SQKQo9fYHoiqE",
                                    "name": "Steve Fishell",
                                    "type": "artist",
                                    "uri": "spotify:artist:2sbjE1Y86SQKQo9fYHoiqE"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 231360,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "QMHLV1300011"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/0VpTmkfMZ5gliNubDshOlE"
                            },
                            "href": "https://api.spotify.com/v1/tracks/0VpTmkfMZ5gliNubDshOlE",
                            "id": "0VpTmkfMZ5gliNubDshOlE",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Feel So Bad - feat. Roosevelt Collier & Steve Fishell",
                            "popularity": 36,
                            "preview_url": "https://p.scdn.co/mp3-preview/9fd951a71c0d2e07fe8c1669dceb6ef3ac069d98?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 11,
                            "type": "track",
                            "uri": "spotify:track:0VpTmkfMZ5gliNubDshOlE"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/0vuwlanMPucXrYMGnOjhYL"
                                },
                                "href": "https://api.spotify.com/v1/albums/0vuwlanMPucXrYMGnOjhYL",
                                "id": "0vuwlanMPucXrYMGnOjhYL",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273d77299e3d29f44495cd7fbcb",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02d77299e3d29f44495cd7fbcb",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851d77299e3d29f44495cd7fbcb",
                                        "width": 64
                                    }
                                ],
                                "name": "40oz. To Freedom",
                                "release_date": "1992-06-01",
                                "release_date_precision": "day",
                                "total_tracks": 22,
                                "type": "album",
                                "uri": "spotify:album:0vuwlanMPucXrYMGnOjhYL"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 185440,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USGA19244450"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/2fXLhLA7cPYEmQ1NbeAtBD"
                            },
                            "href": "https://api.spotify.com/v1/tracks/2fXLhLA7cPYEmQ1NbeAtBD",
                            "id": "2fXLhLA7cPYEmQ1NbeAtBD",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish",
                            "popularity": 51,
                            "preview_url": null,
                            "track_number": 7,
                            "type": "track",
                            "uri": "spotify:track:2fXLhLA7cPYEmQ1NbeAtBD"
                        },
                        {
                            "album": {
                                "album_type": "single",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/1Cq0LAHFfvUTBEtMPXUidI"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/1Cq0LAHFfvUTBEtMPXUidI",
                                        "id": "1Cq0LAHFfvUTBEtMPXUidI",
                                        "name": "O.A.R.",
                                        "type": "artist",
                                        "uri": "spotify:artist:1Cq0LAHFfvUTBEtMPXUidI"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/19xld9FPs6LVf7cwlJyd3I"
                                },
                                "href": "https://api.spotify.com/v1/albums/19xld9FPs6LVf7cwlJyd3I",
                                "id": "19xld9FPs6LVf7cwlJyd3I",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273a6e9a0c9a276ba4ce5196d51",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02a6e9a0c9a276ba4ce5196d51",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851a6e9a0c9a276ba4ce5196d51",
                                        "width": 64
                                    }
                                ],
                                "name": "Badfish",
                                "release_date": "2020-08-21",
                                "release_date_precision": "day",
                                "total_tracks": 1,
                                "type": "album",
                                "uri": "spotify:album:19xld9FPs6LVf7cwlJyd3I"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/1Cq0LAHFfvUTBEtMPXUidI"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/1Cq0LAHFfvUTBEtMPXUidI",
                                    "id": "1Cq0LAHFfvUTBEtMPXUidI",
                                    "name": "O.A.R.",
                                    "type": "artist",
                                    "uri": "spotify:artist:1Cq0LAHFfvUTBEtMPXUidI"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 190186,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "QM6MZ2089784"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/11gYZenDH7L6yX4tiGNrTS"
                            },
                            "href": "https://api.spotify.com/v1/tracks/11gYZenDH7L6yX4tiGNrTS",
                            "id": "11gYZenDH7L6yX4tiGNrTS",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish",
                            "popularity": 25,
                            "preview_url": "https://p.scdn.co/mp3-preview/a227a2b2b7114f118779fa9d58d29235d68376c1?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 1,
                            "type": "track",
                            "uri": "spotify:track:11gYZenDH7L6yX4tiGNrTS"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/12j6dJrPXanCBwY599pZxf"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/12j6dJrPXanCBwY599pZxf",
                                        "id": "12j6dJrPXanCBwY599pZxf",
                                        "name": "Matt Maltese",
                                        "type": "artist",
                                        "uri": "spotify:artist:12j6dJrPXanCBwY599pZxf"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/7I6d6PkjEJE50fnxhCwQ8M"
                                },
                                "href": "https://api.spotify.com/v1/albums/7I6d6PkjEJE50fnxhCwQ8M",
                                "id": "7I6d6PkjEJE50fnxhCwQ8M",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b2730180c5f180f4d69298a02543",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e020180c5f180f4d69298a02543",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d000048510180c5f180f4d69298a02543",
                                        "width": 64
                                    }
                                ],
                                "name": "Bad Contestant",
                                "release_date": "2018-06-08",
                                "release_date_precision": "day",
                                "total_tracks": 11,
                                "type": "album",
                                "uri": "spotify:album:7I6d6PkjEJE50fnxhCwQ8M"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/12j6dJrPXanCBwY599pZxf"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/12j6dJrPXanCBwY599pZxf",
                                    "id": "12j6dJrPXanCBwY599pZxf",
                                    "name": "Matt Maltese",
                                    "type": "artist",
                                    "uri": "spotify:artist:12j6dJrPXanCBwY599pZxf"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 201879,
                            "explicit": true,
                            "external_ids": {
                                "isrc": "GBAHS1800162"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/6vwZCUX7zIFOP8EddhWpXv"
                            },
                            "href": "https://api.spotify.com/v1/tracks/6vwZCUX7zIFOP8EddhWpXv",
                            "id": "6vwZCUX7zIFOP8EddhWpXv",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Like A Fish",
                            "popularity": 40,
                            "preview_url": "https://p.scdn.co/mp3-preview/9c3217792759c470016b0c12b077671b927390cf?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 4,
                            "type": "track",
                            "uri": "spotify:track:6vwZCUX7zIFOP8EddhWpXv"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/4kq4T5cWLlP8NJkMA3S6AN"
                                },
                                "href": "https://api.spotify.com/v1/albums/4kq4T5cWLlP8NJkMA3S6AN",
                                "id": "4kq4T5cWLlP8NJkMA3S6AN",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273aa9ee2492976ff5287afb7bd",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02aa9ee2492976ff5287afb7bd",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851aa9ee2492976ff5287afb7bd",
                                        "width": 64
                                    }
                                ],
                                "name": "Sublime (10th Anniversary Edition / Deluxe Edition)",
                                "release_date": "1996-07-30",
                                "release_date_precision": "day",
                                "total_tracks": 34,
                                "type": "album",
                                "uri": "spotify:album:4kq4T5cWLlP8NJkMA3S6AN"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 171080,
                            "explicit": true,
                            "external_ids": {
                                "isrc": "USGA19649251"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/1Ut0PcWguWEdmYuMmurqfw"
                            },
                            "href": "https://api.spotify.com/v1/tracks/1Ut0PcWguWEdmYuMmurqfw",
                            "id": "1Ut0PcWguWEdmYuMmurqfw",
                            "is_local": false,
                            "is_playable": true,
                            "name": "What I Got",
                            "popularity": 35,
                            "preview_url": null,
                            "track_number": 5,
                            "type": "track",
                            "uri": "spotify:track:1Ut0PcWguWEdmYuMmurqfw"
                        },
                        {
                            "album": {
                                "album_type": "compilation",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/5fJ2bEPDiCX5BLr5PYSauS"
                                },
                                "href": "https://api.spotify.com/v1/albums/5fJ2bEPDiCX5BLr5PYSauS",
                                "id": "5fJ2bEPDiCX5BLr5PYSauS",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273788c265534c34518fca42b46",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02788c265534c34518fca42b46",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851788c265534c34518fca42b46",
                                        "width": 64
                                    }
                                ],
                                "name": "Everything Under The Sun",
                                "release_date": "2006-11-14",
                                "release_date_precision": "day",
                                "total_tracks": 60,
                                "type": "album",
                                "uri": "spotify:album:5fJ2bEPDiCX5BLr5PYSauS"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                }
                            ],
                            "disc_number": 2,
                            "duration_ms": 173960,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USUM70614847"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/7cqjwFbv3JZVsn7Q9sgwMr"
                            },
                            "href": "https://api.spotify.com/v1/tracks/7cqjwFbv3JZVsn7Q9sgwMr",
                            "id": "7cqjwFbv3JZVsn7Q9sgwMr",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish - Rarities Version",
                            "popularity": 27,
                            "preview_url": null,
                            "track_number": 8,
                            "type": "track",
                            "uri": "spotify:track:7cqjwFbv3JZVsn7Q9sgwMr"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/4pJCawaKSZ40EnxN0YEYw3"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/4pJCawaKSZ40EnxN0YEYw3",
                                        "id": "4pJCawaKSZ40EnxN0YEYw3",
                                        "name": "Badfinger",
                                        "type": "artist",
                                        "uri": "spotify:artist:4pJCawaKSZ40EnxN0YEYw3"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/6uA2hir5o45vrILeqQVTbr"
                                },
                                "href": "https://api.spotify.com/v1/albums/6uA2hir5o45vrILeqQVTbr",
                                "id": "6uA2hir5o45vrILeqQVTbr",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273555e940b9bbbe0d8ad5254cf",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02555e940b9bbbe0d8ad5254cf",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851555e940b9bbbe0d8ad5254cf",
                                        "width": 64
                                    }
                                ],
                                "name": "Magic Christian Music (Remastered 2010 / Deluxe Edition)",
                                "release_date": "1970-01-09",
                                "release_date_precision": "day",
                                "total_tracks": 19,
                                "type": "album",
                                "uri": "spotify:album:6uA2hir5o45vrILeqQVTbr"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/4pJCawaKSZ40EnxN0YEYw3"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/4pJCawaKSZ40EnxN0YEYw3",
                                    "id": "4pJCawaKSZ40EnxN0YEYw3",
                                    "name": "Badfinger",
                                    "type": "artist",
                                    "uri": "spotify:artist:4pJCawaKSZ40EnxN0YEYw3"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 146146,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "GBDCE1000108"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/0wlW1FqfNqWK8h40rQoaQM"
                            },
                            "href": "https://api.spotify.com/v1/tracks/0wlW1FqfNqWK8h40rQoaQM",
                            "id": "0wlW1FqfNqWK8h40rQoaQM",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Fisherman - Remastered 2010",
                            "popularity": 39,
                            "preview_url": null,
                            "track_number": 4,
                            "type": "track",
                            "uri": "spotify:track:0wlW1FqfNqWK8h40rQoaQM"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/0vuwlanMPucXrYMGnOjhYL"
                                },
                                "href": "https://api.spotify.com/v1/albums/0vuwlanMPucXrYMGnOjhYL",
                                "id": "0vuwlanMPucXrYMGnOjhYL",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273d77299e3d29f44495cd7fbcb",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02d77299e3d29f44495cd7fbcb",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851d77299e3d29f44495cd7fbcb",
                                        "width": 64
                                    }
                                ],
                                "name": "40oz. To Freedom",
                                "release_date": "1992-06-01",
                                "release_date_precision": "day",
                                "total_tracks": 22,
                                "type": "album",
                                "uri": "spotify:album:0vuwlanMPucXrYMGnOjhYL"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 183000,
                            "explicit": true,
                            "external_ids": {
                                "isrc": "USGA19244445"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/0vtJ9Dq53hfXE3KyP1yPni"
                            },
                            "href": "https://api.spotify.com/v1/tracks/0vtJ9Dq53hfXE3KyP1yPni",
                            "id": "0vtJ9Dq53hfXE3KyP1yPni",
                            "is_local": false,
                            "is_playable": true,
                            "name": "40oz. To Freedom",
                            "popularity": 58,
                            "preview_url": null,
                            "track_number": 2,
                            "type": "track",
                            "uri": "spotify:track:0vtJ9Dq53hfXE3KyP1yPni"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/0IAENkkw32C2AFHv5XvrTq"
                                },
                                "href": "https://api.spotify.com/v1/albums/0IAENkkw32C2AFHv5XvrTq",
                                "id": "0IAENkkw32C2AFHv5XvrTq",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273de39d530355da05edf636cbf",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02de39d530355da05edf636cbf",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851de39d530355da05edf636cbf",
                                        "width": 64
                                    }
                                ],
                                "name": "3 Ring Circus - Live At The Palace",
                                "release_date": "2013-06-01",
                                "release_date_precision": "day",
                                "total_tracks": 27,
                                "type": "album",
                                "uri": "spotify:album:0IAENkkw32C2AFHv5XvrTq"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 207493,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USUM71300293"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/16sHyyLrE4cFM5N2uCURwV"
                            },
                            "href": "https://api.spotify.com/v1/tracks/16sHyyLrE4cFM5N2uCURwV",
                            "id": "16sHyyLrE4cFM5N2uCURwV",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish - Live At The Palace/1995",
                            "popularity": 28,
                            "preview_url": null,
                            "track_number": 9,
                            "type": "track",
                            "uri": "spotify:track:16sHyyLrE4cFM5N2uCURwV"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/03UZwtrCiMR9P10LcjQ5Wu"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/03UZwtrCiMR9P10LcjQ5Wu",
                                        "id": "03UZwtrCiMR9P10LcjQ5Wu",
                                        "name": "Creed Fisher",
                                        "type": "artist",
                                        "uri": "spotify:artist:03UZwtrCiMR9P10LcjQ5Wu"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/384CNTpBbe2MGkqRGYftCx"
                                },
                                "href": "https://api.spotify.com/v1/albums/384CNTpBbe2MGkqRGYftCx",
                                "id": "384CNTpBbe2MGkqRGYftCx",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273a74e2fa6fc155fc44d522568",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02a74e2fa6fc155fc44d522568",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851a74e2fa6fc155fc44d522568",
                                        "width": 64
                                    }
                                ],
                                "name": "Rednecks Like Us",
                                "release_date": "2016-03-29",
                                "release_date_precision": "day",
                                "total_tracks": 11,
                                "type": "album",
                                "uri": "spotify:album:384CNTpBbe2MGkqRGYftCx"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/03UZwtrCiMR9P10LcjQ5Wu"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/03UZwtrCiMR9P10LcjQ5Wu",
                                    "id": "03UZwtrCiMR9P10LcjQ5Wu",
                                    "name": "Creed Fisher",
                                    "type": "artist",
                                    "uri": "spotify:artist:03UZwtrCiMR9P10LcjQ5Wu"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 181277,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "QMPLM1600009"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/0Ijo4JP2lOSM93U6EF2UZx"
                            },
                            "href": "https://api.spotify.com/v1/tracks/0Ijo4JP2lOSM93U6EF2UZx",
                            "id": "0Ijo4JP2lOSM93U6EF2UZx",
                            "is_local": false,
                            "is_playable": true,
                            "name": "If the South Is So Bad, Why'd You Want It Back?",
                            "popularity": 28,
                            "preview_url": "https://p.scdn.co/mp3-preview/c95bb8c9b2ded961b25434da0cdb48484ad44ca5?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 9,
                            "type": "track",
                            "uri": "spotify:track:0Ijo4JP2lOSM93U6EF2UZx"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/4kq4T5cWLlP8NJkMA3S6AN"
                                },
                                "href": "https://api.spotify.com/v1/albums/4kq4T5cWLlP8NJkMA3S6AN",
                                "id": "4kq4T5cWLlP8NJkMA3S6AN",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273aa9ee2492976ff5287afb7bd",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02aa9ee2492976ff5287afb7bd",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851aa9ee2492976ff5287afb7bd",
                                        "width": 64
                                    }
                                ],
                                "name": "Sublime (10th Anniversary Edition / Deluxe Edition)",
                                "release_date": "1996-07-30",
                                "release_date_precision": "day",
                                "total_tracks": 34,
                                "type": "album",
                                "uri": "spotify:album:4kq4T5cWLlP8NJkMA3S6AN"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 211733,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USGA19649256"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/45ysPwEf7vH5yoHCzKtTf3"
                            },
                            "href": "https://api.spotify.com/v1/tracks/45ysPwEf7vH5yoHCzKtTf3",
                            "id": "45ysPwEf7vH5yoHCzKtTf3",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Caress Me Down",
                            "popularity": 32,
                            "preview_url": null,
                            "track_number": 11,
                            "type": "track",
                            "uri": "spotify:track:45ysPwEf7vH5yoHCzKtTf3"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/5bHjVR4F2Tfq4Ha6x7K6wU"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/5bHjVR4F2Tfq4Ha6x7K6wU",
                                        "id": "5bHjVR4F2Tfq4Ha6x7K6wU",
                                        "name": "Rockabye Baby!",
                                        "type": "artist",
                                        "uri": "spotify:artist:5bHjVR4F2Tfq4Ha6x7K6wU"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/1IrDWaCRnhAN4ZTG70KzhV"
                                },
                                "href": "https://api.spotify.com/v1/albums/1IrDWaCRnhAN4ZTG70KzhV",
                                "id": "1IrDWaCRnhAN4ZTG70KzhV",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273a6913d677f582d01ce148d22",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02a6913d677f582d01ce148d22",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851a6913d677f582d01ce148d22",
                                        "width": 64
                                    }
                                ],
                                "name": "Lullaby Renditions of Sublime",
                                "release_date": "2015-10-02",
                                "release_date_precision": "day",
                                "total_tracks": 12,
                                "type": "album",
                                "uri": "spotify:album:1IrDWaCRnhAN4ZTG70KzhV"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/5bHjVR4F2Tfq4Ha6x7K6wU"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/5bHjVR4F2Tfq4Ha6x7K6wU",
                                    "id": "5bHjVR4F2Tfq4Ha6x7K6wU",
                                    "name": "Rockabye Baby!",
                                    "type": "artist",
                                    "uri": "spotify:artist:5bHjVR4F2Tfq4Ha6x7K6wU"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 168079,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USEWC1589616"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/35QEUNqFdvXjxbJiwaB4q1"
                            },
                            "href": "https://api.spotify.com/v1/tracks/35QEUNqFdvXjxbJiwaB4q1",
                            "id": "35QEUNqFdvXjxbJiwaB4q1",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish",
                            "popularity": 22,
                            "preview_url": "https://p.scdn.co/mp3-preview/7c09b25697be1235bae2a831249babab30d70eda?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 1,
                            "type": "track",
                            "uri": "spotify:track:35QEUNqFdvXjxbJiwaB4q1"
                        },
                        {
                            "album": {
                                "album_type": "single",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/6vXuROPBOhPdK6Mce8BzWJ"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/6vXuROPBOhPdK6Mce8BzWJ",
                                        "id": "6vXuROPBOhPdK6Mce8BzWJ",
                                        "name": "FISHTANk",
                                        "type": "artist",
                                        "uri": "spotify:artist:6vXuROPBOhPdK6Mce8BzWJ"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/1Ewp0Al61YJrHEbC5vbGI2"
                                },
                                "href": "https://api.spotify.com/v1/albums/1Ewp0Al61YJrHEbC5vbGI2",
                                "id": "1Ewp0Al61YJrHEbC5vbGI2",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b2733ba1227c657245ffb490b68e",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e023ba1227c657245ffb490b68e",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d000048513ba1227c657245ffb490b68e",
                                        "width": 64
                                    }
                                ],
                                "name": "Bad Intentions",
                                "release_date": "2022-02-11",
                                "release_date_precision": "day",
                                "total_tracks": 1,
                                "type": "album",
                                "uri": "spotify:album:1Ewp0Al61YJrHEbC5vbGI2"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/6vXuROPBOhPdK6Mce8BzWJ"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/6vXuROPBOhPdK6Mce8BzWJ",
                                    "id": "6vXuROPBOhPdK6Mce8BzWJ",
                                    "name": "FISHTANk",
                                    "type": "artist",
                                    "uri": "spotify:artist:6vXuROPBOhPdK6Mce8BzWJ"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 170078,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "TCAGA2269021"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/4huo1es5pGGKBeFwNG7H5k"
                            },
                            "href": "https://api.spotify.com/v1/tracks/4huo1es5pGGKBeFwNG7H5k",
                            "id": "4huo1es5pGGKBeFwNG7H5k",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Bad Intentions",
                            "popularity": 38,
                            "preview_url": "https://p.scdn.co/mp3-preview/337c7cd4900f983d50a95f324d387074ce225469?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 1,
                            "type": "track",
                            "uri": "spotify:track:4huo1es5pGGKBeFwNG7H5k"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/7AR3ee5m8Nwc5s3W2POewq"
                                },
                                "href": "https://api.spotify.com/v1/albums/7AR3ee5m8Nwc5s3W2POewq",
                                "id": "7AR3ee5m8Nwc5s3W2POewq",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b273a5476acb4f8598aa853e3020",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e02a5476acb4f8598aa853e3020",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d00004851a5476acb4f8598aa853e3020",
                                        "width": 64
                                    }
                                ],
                                "name": "Stand By Your Van - Live!",
                                "release_date": "1998-06-23",
                                "release_date_precision": "day",
                                "total_tracks": 16,
                                "type": "album",
                                "uri": "spotify:album:7AR3ee5m8Nwc5s3W2POewq"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                },
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/5tt4msHeXL4Xex9Yon7oTa"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/5tt4msHeXL4Xex9Yon7oTa",
                                    "id": "5tt4msHeXL4Xex9Yon7oTa",
                                    "name": "Michael Happoldt",
                                    "type": "artist",
                                    "uri": "spotify:artist:5tt4msHeXL4Xex9Yon7oTa"
                                },
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/7alpu3Ay4g4CQoXmF4ni2c"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/7alpu3Ay4g4CQoXmF4ni2c",
                                    "id": "7alpu3Ay4g4CQoXmF4ni2c",
                                    "name": "Eddie Ashworth",
                                    "type": "artist",
                                    "uri": "spotify:artist:7alpu3Ay4g4CQoXmF4ni2c"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 182973,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USGA19457914"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/70P6a9MQQ4vWyYKUdZeF5I"
                            },
                            "href": "https://api.spotify.com/v1/tracks/70P6a9MQQ4vWyYKUdZeF5I",
                            "id": "70P6a9MQQ4vWyYKUdZeF5I",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish - Live At Kommotion/1994",
                            "popularity": 23,
                            "preview_url": null,
                            "track_number": 8,
                            "type": "track",
                            "uri": "spotify:track:70P6a9MQQ4vWyYKUdZeF5I"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/33NScerSaBJ9Y1YndrE5sL"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/33NScerSaBJ9Y1YndrE5sL",
                                        "id": "33NScerSaBJ9Y1YndrE5sL",
                                        "name": "The Scary Jokes",
                                        "type": "artist",
                                        "uri": "spotify:artist:33NScerSaBJ9Y1YndrE5sL"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/07m8r1LU1IZCVDjeWVtcXG"
                                },
                                "href": "https://api.spotify.com/v1/albums/07m8r1LU1IZCVDjeWVtcXG",
                                "id": "07m8r1LU1IZCVDjeWVtcXG",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b2734be441c306252e3f48740b0e",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e024be441c306252e3f48740b0e",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d000048514be441c306252e3f48740b0e",
                                        "width": 64
                                    }
                                ],
                                "name": "Bad at Math",
                                "release_date": "2014-12-24",
                                "release_date_precision": "day",
                                "total_tracks": 9,
                                "type": "album",
                                "uri": "spotify:album:07m8r1LU1IZCVDjeWVtcXG"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/33NScerSaBJ9Y1YndrE5sL"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/33NScerSaBJ9Y1YndrE5sL",
                                    "id": "33NScerSaBJ9Y1YndrE5sL",
                                    "name": "The Scary Jokes",
                                    "type": "artist",
                                    "uri": "spotify:artist:33NScerSaBJ9Y1YndrE5sL"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 382013,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "QZB4J1841263"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/5zYK0Nx8G41XZQyE89Q2Fe"
                            },
                            "href": "https://api.spotify.com/v1/tracks/5zYK0Nx8G41XZQyE89Q2Fe",
                            "id": "5zYK0Nx8G41XZQyE89Q2Fe",
                            "is_local": false,
                            "is_playable": true,
                            "name": "The Daughter of the Fish and the Ram",
                            "popularity": 28,
                            "preview_url": "https://p.scdn.co/mp3-preview/ec8b277d0f43bc908e0f5c8724a7a6b630eef20f?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 3,
                            "type": "track",
                            "uri": "spotify:track:5zYK0Nx8G41XZQyE89Q2Fe"
                        },
                        {
                            "album": {
                                "album_type": "compilation",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                        "id": "0EdvGhlC1FkGItLOWQzG4J",
                                        "name": "Sublime",
                                        "type": "artist",
                                        "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/4t34GYhLf66iqbTr45aLdi"
                                },
                                "href": "https://api.spotify.com/v1/albums/4t34GYhLf66iqbTr45aLdi",
                                "id": "4t34GYhLf66iqbTr45aLdi",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b27361866cb72b9652f3f2ce73d3",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e0261866cb72b9652f3f2ce73d3",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d0000485161866cb72b9652f3f2ce73d3",
                                        "width": 64
                                    }
                                ],
                                "name": "Greatest Hits",
                                "release_date": "1999-11-09",
                                "release_date_precision": "day",
                                "total_tracks": 10,
                                "type": "album",
                                "uri": "spotify:album:4t34GYhLf66iqbTr45aLdi"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/0EdvGhlC1FkGItLOWQzG4J",
                                    "id": "0EdvGhlC1FkGItLOWQzG4J",
                                    "name": "Sublime",
                                    "type": "artist",
                                    "uri": "spotify:artist:0EdvGhlC1FkGItLOWQzG4J"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 187026,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USGA19244450"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/0UNPv9R0QIS0DiBBoGZsFf"
                            },
                            "href": "https://api.spotify.com/v1/tracks/0UNPv9R0QIS0DiBBoGZsFf",
                            "id": "0UNPv9R0QIS0DiBBoGZsFf",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish",
                            "popularity": 23,
                            "preview_url": null,
                            "track_number": 8,
                            "type": "track",
                            "uri": "spotify:track:0UNPv9R0QIS0DiBBoGZsFf"
                        },
                        {
                            "album": {
                                "album_type": "album",
                                "artists": [
                                    {
                                        "external_urls": {
                                            "spotify": "https://open.spotify.com/artist/5p3WimI9yquAF6Lqhlm4Ol"
                                        },
                                        "href": "https://api.spotify.com/v1/artists/5p3WimI9yquAF6Lqhlm4Ol",
                                        "id": "5p3WimI9yquAF6Lqhlm4Ol",
                                        "name": "No Use For A Name",
                                        "type": "artist",
                                        "uri": "spotify:artist:5p3WimI9yquAF6Lqhlm4Ol"
                                    }
                                ],
                                "external_urls": {
                                    "spotify": "https://open.spotify.com/album/7vbPS3XqLL5OEQ47r71eC5"
                                },
                                "href": "https://api.spotify.com/v1/albums/7vbPS3XqLL5OEQ47r71eC5",
                                "id": "7vbPS3XqLL5OEQ47r71eC5",
                                "images": [
                                    {
                                        "height": 640,
                                        "url": "https://i.scdn.co/image/ab67616d0000b27365fe8df9befd432f427a6b36",
                                        "width": 640
                                    },
                                    {
                                        "height": 300,
                                        "url": "https://i.scdn.co/image/ab67616d00001e0265fe8df9befd432f427a6b36",
                                        "width": 300
                                    },
                                    {
                                        "height": 64,
                                        "url": "https://i.scdn.co/image/ab67616d0000485165fe8df9befd432f427a6b36",
                                        "width": 64
                                    }
                                ],
                                "name": "Rarities Vol. 1: The Covers",
                                "release_date": "2017-08-11",
                                "release_date_precision": "day",
                                "total_tracks": 13,
                                "type": "album",
                                "uri": "spotify:album:7vbPS3XqLL5OEQ47r71eC5"
                            },
                            "artists": [
                                {
                                    "external_urls": {
                                        "spotify": "https://open.spotify.com/artist/5p3WimI9yquAF6Lqhlm4Ol"
                                    },
                                    "href": "https://api.spotify.com/v1/artists/5p3WimI9yquAF6Lqhlm4Ol",
                                    "id": "5p3WimI9yquAF6Lqhlm4Ol",
                                    "name": "No Use For A Name",
                                    "type": "artist",
                                    "uri": "spotify:artist:5p3WimI9yquAF6Lqhlm4Ol"
                                }
                            ],
                            "disc_number": 1,
                            "duration_ms": 180026,
                            "explicit": false,
                            "external_ids": {
                                "isrc": "USFW41797206"
                            },
                            "external_urls": {
                                "spotify": "https://open.spotify.com/track/0Fm7hskGskRBElGlI9d2Gi"
                            },
                            "href": "https://api.spotify.com/v1/tracks/0Fm7hskGskRBElGlI9d2Gi",
                            "id": "0Fm7hskGskRBElGlI9d2Gi",
                            "is_local": false,
                            "is_playable": true,
                            "name": "Badfish",
                            "popularity": 24,
                            "preview_url": "https://p.scdn.co/mp3-preview/3aff7738d1dd3dccba2421eaf523ebdd1b1770c3?cid=8e4354ac70704490ae345778831c62c1",
                            "track_number": 6,
                            "type": "track",
                            "uri": "spotify:track:0Fm7hskGskRBElGlI9d2Gi"
                        }
                    ],
                    "limit": 20,
                    "next": "https://api.spotify.com/v1/search?query=bad+fish&type=track&include_external=audio&market=US&offset=20&limit=20",
                    "offset": 0,
                    "previous": null,
                    "total": 564
                }
            }
    };

    const errorSample = {
        "error": true,
        "message": "Error while getting search results",
        "payload": []
    }

    const emptySample = {
        "error": false,
        "message": "",
        "payload": []
    }
    
    function MockDataHelper() {

    }

    MockDataHelper.prototype.getMockData = function (sampleName) {
        if (sampleName === "badfish")
            return badfish;

        if (sampleName === "empty")
            return emptySample;
        
        return errorSample;
    }

    return MockDataHelper;

})();
exports.MockDataHelper = MockDataHelper;